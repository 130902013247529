body {
  background-color:black!important;
  color: white !important;
}

.graphTd {
  border-top: 1px solid white
}

.graphYtd {
  border-top: 1px solid white
}

.graphContainer {
  height: 80vh;
}